require('./bootstrap');
window.bootstrap = require('bootstrap');
window.DatePicker = require('tui-date-picker');
window.colorPicker = require('tui-color-picker');

window.dayjs = require('dayjs');
require('dayjs/locale/ko');
const advanceFormat = require('dayjs/plugin/advancedFormat');
const arraySupport = require("dayjs/plugin/arraySupport");
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isToday = require('dayjs/plugin/isToday')
const isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(isTomorrow)
dayjs.extend(isToday)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(advanceFormat)
dayjs.extend(arraySupport)
dayjs.locale('ko');

window.tintmce = require('tinymce');

const sidemenu = document.getElementById('sidemenu');
if ( sidemenu ) {
    const currentMenu = sidemenu.querySelector(`a[href="${location.href.replace(/https?:/gi, '')}"]`);
    if ( currentMenu ) {
        currentMenu.classList.add('active');
    }
}


window.logout = function(){
    const logoutForm = document.getElementById('logout_form');
    logoutForm.submit();
}
